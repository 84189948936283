<template>
  <div id="mbti-result" class="section">
    <div class="jumbotron text-right">
      <div class="container">
        <h2>Tipe kepribadianmu adalah</h2>
        <h1>ESTJ</h1>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <p>
            Anda yang memiliki kepribadian ESTJ biasa disebut sebagai The Guardians atau pelindung. Ada banyak yakni  8-12% kepribadian ESTJ di dunia. Menurut dimensinya ESTJ adalah Extroversion yaitu mereka yang bisa membangun jaringan dengan luas. Sensing, yakni mereka yang tidak suka akan hal abstrak dan lebih suka hal konkret. Kemudian Thinking yakni mereka yang berpikir objektif dalam berbagai hal dan Judging, yakni mereka yang senang membuat rencana dulu baru menjalankannya. 
          </p>
          <p>
            Ciri kepribadian ESTJ adalah:
            <ul>
              <li> Pemimpin dan pelindung yang alami.</li>
              <li> Memiliki aturan standar dan sering disebut kaku atau tradisional.</li>
              <li> Biasanya gemar akan kesehatan atau olahraga.</li>
              <li> Senang menciptakan ketertiban dan sangat teliti.</li>
              <li> Berterus terang dan juga jujur.</li>
            </ul>
          </p>
          <p>
            Saran dan pekerjaan yang cocok:
            <ul>
              <li>
                Kurangi untuk mengontrol dan memaksa orang lain.
              </li>
              <li>
                Introspeksi diri dan jangan lupa untuk mengendalikan amarah.
              </li>
              <li>
                Pekerjaan yang cocok adalah hakim, polisi, pimpinan militer, petugas keuangan dan detektif.
              </li>
            </ul>
          </p>
          <p>
            Asmara yang cocok dengan ESTJ adalah seseorang yang memiliki kepribadian ISTP atau INTJ yang mana tidak akan saling mendominasi dan juga menenangkan.
          </p>
        </div>
        <div class="col-lg-5">
          <img src="../../../assets/images/mbti/ESTJ.png" alt="" class="illustration">
          <div class="media">
            <img src="../../../assets/images/mbti/Extraversion.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="extraversion">Extraversion</h5>
              <p>cenderung memiliki jaringan sosial yang luas</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Sensing.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="sensing">Sensing</h5>
              <p>lebih fokus terhadap gambaran masa depan</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Thinking.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="thinking">Thinking</h5>
              <p>lebih fokus terhadap implikasi sosial dibandingkan logika</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Judgement.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="judgement">Judgement</h5>
              <p>menyukai hal yang bersifat terograrnisir dan terstruktur</p>
            </div>
          </div>
        </div>
      </div>
      <ShareButton :result="result" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ESTJ",
  components: {
    ShareButton: () => import('@/components/ShareButton'),
  },
  data() {
    return {
      result: {
        name: 'ESTJ',
        slug: 'estj'
      }
    }
  }
};
</script>

<style scoped>

</style>
